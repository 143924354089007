<template>
  <nav
    v-if="maxPage > 1"
    aria-label="pagination"
    class="section u-mt-0"
  >
    <ul class="c-pagination">
      <li
        class="c-pagination__item c-pagination__item--first"
        :class="{ 'is-disabled': page === firstPage }"
      >
        <Component
          :is="page === firstPage ? 'span' : 'a'"
          class="c-pagination__link"
          :href="inlineHref(firstPage, page === firstPage)"
          :aria-disabled="page === firstPage"
          @click="goToPage($event, firstPage, page === firstPage)"
        >
          <span class="h-vhd"> Première page </span>
        </Component>
      </li>
      <li
        class="c-pagination__item c-pagination__item--prev"
        :class="{ 'is-disabled': page <= prevPage }"
      >
        <Component
          :is="page <= prevPage ? 'span' : 'a'"
          class="c-pagination__link"
          :href="inlineHref(prevPage, page <= prevPage)"
          :aria-disabled="page <= prevPage"
          @click="goToPage($event, prevPage, page <= prevPage)"
        >
          <span class="h-vhd">Page précédente</span>
        </Component>
      </li>
      <li
        v-for="(i, _i) in pageRange"
        :key="`${paginationKey}${i === '...' ? `dot-${_i}` : i}`"
        class="c-pagination__item"
        :class="{ 'is-current': page === i }"
      >
        <a
          v-if="i !== '...'"
          class="c-pagination__link"
          :href="inlineHref(i)"
          :aria-current="page === i ? 'page' : undefined"
          @click="goToPage($event, i, page === i)"
        >
          <span class="h-vhd">Page </span>
          {{ i }}
        </a>
        <span
          v-else
          class="c-pagination__link"
        >
          {{ i }}
        </span>
      </li>
      <li
        class="c-pagination__item c-pagination__item--next"
        :class="{ 'is-disabled': page >= nextPage }"
      >
        <Component
          :is="page >= nextPage ? 'span' : 'a'"
          class="c-pagination__link"
          :href="inlineHref(nextPage, page >= nextPage)"
          :aria-disabled="page >= nextPage"
          @click="goToPage($event, nextPage, page >= nextPage)"
        >
          <span class="h-vhd">Page suivante</span>
        </Component>
      </li>
      <li
        class="c-pagination__item c-pagination__item--last"
        :class="{ 'is-disabled': page === lastPage }"
      >
        <Component
          :is="page === lastPage ? 'span' : 'a'"
          class="c-pagination__link"
          :href="inlineHref(lastPage, page === lastPage)"
          :aria-disabled="page === lastPage"
          @click="goToPage($event, lastPage, page === lastPage)"
        >
          <span class="h-vhd">Dernière page</span>
        </Component>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
const route = useRoute()

const props = withDefaults(
  defineProps<{
    totalPages: number
    goTo: (page: number) => void
    currentPage?: number
    pageOffset?: number
    paginationKey?: string
    routing?: boolean
  }>(),
  {
    currentPage: 1,
    pageOffset: 2,
    paginationKey: 'page-',
    routing: true,
  },
)

const dataCurrent = ref((route.query?.page as unknown as number) || 1)

const current = computed(() => {
  return dataCurrent.value
})

const maxPage = computed(() => {
  return props.totalPages
})

const page = computed(() => {
  return Math.min(maxPage.value, Math.max(1, current.value))
})

const prevPage = computed(() => {
  return Math.max(1, page.value - 1)
})

const nextPage = computed(() => {
  return Math.min(maxPage.value, page.value + 1)
})

const firstPage = computed(() => {
  return 1
})

const lastPage = computed(() => {
  return props.totalPages
})

const pageRange = computed(() => {
  const max = props.totalPages
  const pages = [1, max]

  ;[...Array(max.toString().length)].forEach((_, index) => {
    const step = 10 ** index
    const multiplier = step * 10

    let start =
      step === 1
        ? Math.floor(current.value / multiplier) * multiplier || 1
        : Math.ceil(current.value / step) * step
    let end = Math.min(max, Math.ceil(current.value / multiplier) * multiplier)

    if (start === end && end % multiplier === 0) {
      end += multiplier
    }

    if (start < max) {
      while (start <= end) {
        pages.push(start)
        start += step
      }
    }
  })

  const sortedPages: (string | number)[] = pages
    .filter((a) => a <= max) // remove upper totalPage values
    .filter((v, i, a) => a.indexOf(v) === i) // remove duplicate
    .sort((a, b) => a - b)

  const first = sortedPages.shift()
  const last = sortedPages.pop()

  if (
    typeof first === 'number' &&
    sortedPages.at(0) &&
    sortedPages.at(0) !== first + 1
  ) {
    sortedPages.unshift('...')
  }

  if (
    typeof last === 'number' &&
    // sortedPages.at(-1) &&
    sortedPages.at(-1) !== last - 1
  ) {
    sortedPages.push('...')
  }

  return [first, ...sortedPages, last]
})

watch(
  () => props.currentPage,
  (val) => {
    if (val && dataCurrent.value !== val) {
      updateRoute(val)
    }
  },
)

const goToPage = (event: Event, page?: string | number, disabled = false) => {
  event.preventDefault()

  if (!disabled && typeof page === 'number') {
    updateRoute(page)
    props.goTo(page)
  }
}

const inlineHref = (page?: string | number, disabled = false) => {
  if (disabled || typeof page !== 'number') return undefined

  return `${route.path}${page > 1 ? `?page=${page}` : ''}`
}

const updateRoute = (page: number) => {
  dataCurrent.value = page

  if (props.routing) {
    return history.pushState(
      {},
      '',
      `${route.path}${page > 1 ? `?page=${page}` : ''}`,
    )
  }
}
</script>

<style lang="scss" scoped>
.h-vhd {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
}

.c-pagination {
  $_root-pagination: &;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 7rem auto 0;

  @media (min-width: 992px) {
    width: 75%;
  }

  @media (min-width: 1400px) {
    width: calc(100% * 2 / 3);
  }

  @media (min-width: 1920px) {
    width: 50%;
  }

  &__item {
    display: block;
    min-width: 30px;
    height: 30px;
    margin: 0 1px;
    font-size: 14px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
    transition: background-color 0.35s ease-in-out;
    user-select: none;

    @media (min-width: 414px) {
      min-width: 35px;
      height: 35px;
      line-height: 35px;
    }

    @media (min-width: 992px) {
      min-width: 40px;
      height: 40px;
      margin: 0 4px;
      line-height: 40px;
    }

    &#{&}--prev,
    &#{&}--next,
    &#{&}--first,
    &#{&}--last {
      #{$_root-pagination}__link {
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px 16px;
      }
    }

    &#{&}--first,
    &#{&}--last {
      #{$_root-pagination}__link {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E\%3Cpath fill='%23000' d='m5.4 9.8 4.4-4.4c.2-.2.2-.6 0-.9-.2-.2-.6-.2-.9 0L5 8.5 1.1 4.6c-.2-.2-.6-.2-.9 0-.2.2-.2.6 0 .9l4.4 4.4c.2.2.6.2.8-.1z'/%3E\%3Cpath fill='%23000' d='M5.4 5.4 9.8 1c.2-.2.2-.6 0-.9-.2-.2-.6-.2-.9 0L5 4.1 1.1.2C.9 0 .5 0 .2.2c-.2.2-.2.6 0 .9l4.4 4.4c.2.2.6.2.8-.1z'/%3E\%3C/svg%3E");
      }

      &.is-disabled {
        #{$_root-pagination}__link {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Cpath fill='%23999' d='m5.4 9.8 4.4-4.4c.2-.2.2-.6 0-.9-.2-.2-.6-.2-.9 0L5 8.5 1.1 4.6c-.2-.2-.6-.2-.9 0-.2.2-.2.6 0 .9l4.4 4.4c.2.2.6.2.8-.1z'/%3E%3Cpath fill='%23999' d='M5.4 5.4 9.8 1c.2-.2.2-.6 0-.9-.2-.2-.6-.2-.9 0L5 4.1 1.1.2C.9 0 .5 0 .2.2c-.2.2-.2.6 0 .9l4.4 4.4c.2.2.6.2.8-.1z'/%3E%3C/svg%3E");
        }
      }
    }

    &#{&}--prev,
    &#{&}--next {
      #{$_root-pagination}__link {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Cpath fill='%23000' d='m5.4 7.6 4.4-4.4c.2-.2.2-.6 0-.9-.2-.2-.6-.2-.9 0L5 6.3 1.1 2.4c-.2-.2-.6-.2-.9 0-.2.2-.2.6 0 .9l4.4 4.4c.2.2.6.2.8-.1z'/%3E%3C/svg%3E");
      }

      &.is-disabled {
        #{$_root-pagination}__link {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Cpath fill='%23999' d='m5.4 7.6 4.4-4.4c.2-.2.2-.6 0-.9-.2-.2-.6-.2-.9 0L5 6.3 1.1 2.4c-.2-.2-.6-.2-.9 0-.2.2-.2.6 0 .9l4.4 4.4c.2.2.6.2.8-.1z'/%3E%3C/svg%3E");
        }
      }
    }

    &#{&}--first,
    &#{&}--prev {
      #{$_root-pagination}__link {
        transform: rotate(90deg);
      }
    }

    &#{&}--last,
    &#{&}--next {
      #{$_root-pagination}__link {
        transform: rotate(-90deg);
      }
    }

    &:hover:not(.is-current):not(.is-disabled) {
      #{$_root-pagination}__link:is(a) {
        background-color: #eee;
        cursor: pointer;
      }
    }

    &.is-current {
      #{$_root-pagination}__link {
        color: #fff;
        background-color: #000;
      }
    }

    &.is-disabled {
      #{$_root-pagination}__link {
        color: #222;
      }
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 2px;
    color: inherit;
    text-decoration: none;
    transition: box-shadow 0.35s ease-in-out;

    &:focus {
      :not(.is-disabled) & {
        outline: 0;
        box-shadow: 0 0 0 2px #000;
      }
    }

    @media (min-width: 992px) {
      padding: 0 4px;
    }
  }
}
</style>
